/* eslint-disable @next/next/no-img-element */
import { getCookie } from 'framework/libs/cookie';
import { TOKEN_CONGDONG } from 'framework/store/constants';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import http from 'utilities/http';
import dayjs from 'dayjs';
import { numberWithCommas, changeMonthFirst } from 'utilities/helper';
import StarList from 'components/shop/review/starList';
import { Kho360 } from 'components/cong-dong/kho360';
import {useCart} from "../../framework/store";

const LABEL = {
    0: 'Nháp',
    1: '',
    2: 'Chờ duyệt',
    100: 'Mới',
};

export default function ImageComponent({
    item,
    index,
    slug,
    isUserPage,
    author,
    perRow = 3,
    show_url,
    slug_mini_site = ""
}) {
    let link = getLinkArticle(item, slug);
    const thumbnail = getThumbnail(item);
    const [stateStore, actions] = useCart();
    if(!isEmpty(stateStore?.informationSite?.slug)){
        link +=`?site_url=${stateStore?.informationSite?.slug}`;
    }
    if (slug == 'kho-360') {
        return (
            <Kho360
                item={item}
                index={index}
                perRow={perRow}
                thumbnail={thumbnail}
                link={link}
            />
        );
    }

    return (
        <li key={index}>
            <Article
                item={item}
                index={index}
                slug={slug}
                isUserPage={isUserPage}
                author={author}
                perRow={perRow}
                show_url={show_url}
                slug_mini_site={slug_mini_site}
            />
        </li>
    );
}

export const Article = ({
    item,
    index,
    slug,
    isUserPage,
    author,
    perRow = 3,
    show_url,
    slug_mini_site = ""
}) => {
    //console.log(slug);
    const [currentPin, setCurrentPin] = useState(null);
    const [stateStore, actions] = useCart();

    useEffect(() => {
        setCurrentPin(item.current_user_pinned);
    }, [item]);

    const actionLike = async (postType, id, act) => {
        if (isEmpty(getCookie(TOKEN_CONGDONG))) {
            toast.info('Bạn cần đăng nhập để thao tác.');
            return;
        }
        if (act === 'pins') {
            setCurrentPin(!currentPin);
        }

        const res = await http(
            `community/${postType}/${act}/toggle`,
            {},
            {
                body: { id: id },
                method: 'POST',
                token: 'Bearer ' + getCookie(TOKEN_CONGDONG),
            },
            'v2',
        );
    };

    let link = show_url || getLinkArticle(item, slug);
    if(!isEmpty(stateStore?.informationSite?.slug)){
        link +=`?site_url=${stateStore.informationSite.slug}`;
    }
    const thumbnail = getThumbnail(item);

    if (
        item.status_code == 1 &&
        dayjs().diff(
            dayjs(changeMonthFirst(item.published_at_detail_formatted)),
            'day',
        ) < 2
    ) {
        item.status_code = 100;
    }

    return (
        <div className="photoStore">
            {!isUserPage &&
                item.user &&
                item.post_type_id != 4 &&
                !item.all_terms?.data?.some((x) => x.id == 572) &&
                author && (
                <div className="photoStore-head">
                    <div className="photoStore-head__item">
                        {item.user?.avatar && (
                            <Image
                                layout="fill"
                                src={item.user.avatar}
                                alt=""
                            />
                        )}
                    </div>
                    <div className="photoStore-head__text">
                        <div>
                            <span
                                className="tlt"
                                style={
                                    !item.user?.infoProfessional
                                        ? { float: 'left' }
                                        : {}
                                }
                            >
                                {item.user.display_name}
                            </span>
                            {slug === 'review' && item?.rating > 0 && (
                                <>
                                    <StarList
                                        number={item.rating}
                                        style={{
                                            float: 'right',
                                            height: '22.4px',
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        {item.user?.infoProfessional && (
                            <p className="lable professional">
                                {item.user.infoProfessional || ' '}
                            </p>
                        )}
                    </div>
                </div>
            )}
            <div className="photoStore-thumb">
                <a href={link} className="wrap-next-image">
                    <Image layout="fill" src={thumbnail} alt="" />
                </a>
                {LABEL[item.status_code] && (
                    <div className="photoStore-lable">
                        <span>{LABEL[item.status_code]}</span>
                    </div>
                )}
                {isUserPage && (
                    <div className="read-views">
                        <Image
                            layout={'fill'}
                            className="svg"
                            src="/images/icon-tick.svg"
                            alt=""
                        />{' '}
                        <span className="number">
                            {numberWithCommas(
                                item.interaction_count?.view_count_number,
                            )}
                        </span>
                    </div>
                )}
                <button
                    className="btn-save-bookmart"
                    onClick={() =>
                        actionLike(item?.post_type, item?.id, 'pins')
                    }
                >
                    <Image
                        width={18}
                        height={21}
                        src={
                            currentPin
                                ? '/images/icon-bookmark03.svg'
                                : '/images/icon-bookmark.svg'
                        }
                        align=""
                        alt=""
                    />
                </button>
                {item.is_review && (
                    <div className="read-views">
                        <Image
                            layout={'fill'}
                            className="svg"
                            src="/images/icon-tick.svg"
                            alt=""
                            style={{ marginBottom: 2 }}
                        />
                        <span className="number">
                            {numberWithCommas(
                                item.interaction_count?.view_count_number,
                            )}
                        </span>
                    </div>
                )}
                {(item.post_type == 'video' ||
                    item.first_term?.id == 303 ||
                    slug == 'happynesttv') && (
                    <a href={link}>
                        <div className="play-button">
                            <img
                                style={{ width: 70, height: 70 }}
                                className="svg"
                                src="/images/Play.png"
                                alt=""
                            />
                        </div>
                    </a>
                )}
            </div>
            <ul className="photoStore-item">
                {(item.terms?.data.filter((x) => x.name != 'Khác') || [])
                    .slice(0, 3)
                    .map((tag, index) => {
                        return <li key={index}>{tag?.name}</li>;
                    })}
            </ul>
            <h2
                className="photoStore-title"
                style={{ height: 39, overflow: 'hidden' }}
            >
                <a href={link} style={{ width: '100%' }}>
                    {item.title}
                </a>
            </h2>

            {(item.post_type_id == 4 ||
                item.all_terms?.data?.some((x) => x.id == 572)) &&
                !isUserPage && (
                <div className="discussion-acc">
                    <span>
                        <img
                            src={item.user.avatar || '/images/icon-app.svg'}
                            alt=""
                        />
                    </span>
                    {item.user.display_name}
                </div>
            )}
            {!isUserPage && (
                <div
                    className="photoStore-date"
                    style={{ position: 'relative' }}
                >
                    {item.published_at_detail_formatted && (
                        <span>{item.published_at_detail_formatted}</span>
                    )}
                    {!item.published_at_detail_formatted && item.created_at && (
                        <span>
                            {dayjs(item.created_at).format('DD') +
                                ' tháng ' +
                                dayjs(item.created_at).format('MM, YYYY')}
                        </span>
                    )}
                    <span style={{ position: 'absolute', right: 0 }}>
                        {numberWithCommas(
                            item?.interaction_count?.like_count_number,
                        )}{' '}
                        lượt thích |{' '}
                        {numberWithCommas(
                            item?.interaction_count?.view_count_number,
                        )}{' '}
                        lượt xem
                    </span>
                </div>
            )}
        </div>
    );
};

export const getLinkArticle = (item, slug) => {
    let link =
        (item.status_code == 0 || item.status_code == 2 ? '/preview' : '') +
        `/${slug}/${item.id}/${item.slug}`;
    if (item.storage_emagazine_url) {
        link =
            (item.status_code == 0 || item.status_code == 2 ? '/preview' : '') +
            `/happynest-story/` +
            item.id +
            '/' +
            item.slug;
    }
    return link;
};

export const getThumbnail = (item) => {
    let thumbnail =
        item.storage_thumbnail_url_wide ||
        item.storage_thumbnail_url ||
        item.storage_thumbnail_url_large;
    if (
        item.storage_thumbnail_url_large ===
            'https://happynest.vn/home2017-assets/images/avatar-happynest@2x.jpg' &&
        !isEmpty(item.images?.data)
    ) {
        thumbnail =
            item.images?.data[0]?.storage_thumbnail_url ||
            item.images?.data[0]?.storage_url;
    }
    return thumbnail;
};

export const ArticleSmall = ({
    item,
    index,
    slug,
    isUserPage,
    author,
    perRow = 3,
    show_url,
    displayViewLike
}) => {
    const [currentPin, setCurrentPin] = useState(null);

    useEffect(() => {
        setCurrentPin(item.current_user_pinned);
    }, [item]);

    const actionLike = async (postType, id, act) => {
        if (isEmpty(getCookie(TOKEN_CONGDONG))) {
            toast.info('Bạn cần đăng nhập để thao tác.');
            return;
        }
        if (act === 'pins') {
            setCurrentPin(!currentPin);
        }

        const res = await http(
            `community/${postType}/${act}/toggle`,
            {},
            {
                body: { id: id },
                method: 'POST',
                token: 'Bearer ' + getCookie(TOKEN_CONGDONG),
            },
            'v2',
        );
    };

    const link = show_url || getLinkArticle(item, slug);
    const thumbnail = getThumbnail(item);

    if (
        item.status_code == 1 &&
        dayjs().diff(
            dayjs(changeMonthFirst(item.published_at_detail_formatted)),
            'day',
        ) < 2
    ) {
        item.status_code = 100;
    }

    return (
        <div className="photoStore">
            {!isUserPage &&
                item.user &&
                item.post_type_id != 4 &&
                !item.all_terms?.data?.some((x) => x.id == 572) &&
                author && (
                <div className="photoStore-head">
                    <div className="photoStore-head__item">
                        {item.user?.avatar && (
                            <Image
                                layout="fill"
                                src={item.user.avatar}
                                alt=""
                            />
                        )}
                    </div>
                    <div className="photoStore-head__text">
                        <div>
                            <span
                                className="tlt"
                                style={
                                    !item.user?.infoProfessional
                                        ? { float: 'left' }
                                        : {}
                                }
                            >
                                {item.user.display_name}
                            </span>
                            {slug === 'review' && item?.rating > 0 && (
                                <>
                                    <StarList
                                        number={item.rating}
                                        style={{
                                            float: 'right',
                                            height: '22.4px',
                                        }}
                                    />
                                </>
                            )}
                        </div>
                        {item.user?.infoProfessional && (
                            <p className="lable professional">
                                {item.user.infoProfessional || ' '}
                            </p>
                        )}
                    </div>
                </div>
            )}
            <div className="photoStore-thumb">
                <a href={link} className="wrap-next-image">
                    <Image layout="fill" src={thumbnail} alt="" />
                </a>
                {LABEL[item.status_code] && (
                    <div className="photoStore-lable">
                        <span>{LABEL[item.status_code]}</span>
                    </div>
                )}
                {
                    <div className="read-views" style={{ left: 8, bottom: 8 }}>
                        <Image
                            layout={'fill'}
                            className="svg"
                            src="/images/icon-tick.svg"
                            alt=""
                        />{' '}
                        <span className="number font-size-12">
                            {numberWithCommas(
                                item.interaction_count?.view_count_number,
                            )}
                        </span>
                    </div>
                }
                <button
                    className="btn-save-bookmart"
                    onClick={() =>
                        actionLike(item?.post_type, item?.id, 'pins')
                    }
                    style={{ right: 8, bottom: 8 }}
                >
                    <Image
                        width={16}
                        height={19}
                        src={
                            currentPin
                                ? '/images/icon-bookmark03.svg'
                                : '/images/icon-bookmark.svg'
                        }
                        align=""
                        alt=""
                    />
                </button>
                {(item.post_type == 'video' ||
                    item.first_term?.id == 303 ||
                    slug == 'happynesttv') && (
                    <a href={link}>
                        <div className="play-button">
                            <img
                                style={{ width: 70, height: 70 }}
                                className="svg"
                                src="/images/Play.png"
                                alt=""
                            />
                        </div>
                    </a>
                )}
            </div>
            <ul className="photoStore-item">
                {(item.terms?.data.filter((x) => x.name != 'Khác') || [])
                    .slice(0, 3)
                    .map((tag, index) => {
                        return <li key={index}>{tag?.name}</li>;
                    })}
            </ul>
            <a
                href={link}
                className="font-size-14 color-222 font-weight-600 display-2-line"
                style={{ width: '100%', overflow: 'hidden' }}
            >
                {item.title}
            </a>

            <div className="font-size-12 font-weight-400 color-666 mt-1">
                {item.user.display_name}
            </div>

            {displayViewLike && (
                <div
                    className="photoStore-date"
                    style={{ position: 'relative' }}
                >
                    {item.published_at_detail_formatted && (
                        <span>{item.published_at_detail_formatted}</span>
                    )}
                    {!item.published_at_detail_formatted && item.created_at && (
                        <span>
                            {dayjs(item.created_at).format('DD') +
                                ' tháng ' +
                                dayjs(item.created_at).format('MM, YYYY')}
                        </span>
                    )}
                    <span style={{ position: 'absolute', right: 0 }}>
                        {numberWithCommas(
                            item?.interaction_count?.like_count_number,
                        )}{' '}
                        lượt thích |{' '}
                        {numberWithCommas(
                            item?.interaction_count?.view_count_number,
                        )}{' '}
                        lượt xem
                    </span>
                </div>
            )}
        </div>
    );
};
