/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
export default function Index({ number, size = 14, onClickFunc, style = {} }) {
    const reviewSummary = Math.round(+number || 0);

    return (
        <ul className="star-list" style={style}>
            {[1, 2, 3, 4, 5].map(item => {
                return (<li key={item} style={{ display: "flex" }}>
                    <Image
                        width={size} height={size}
                        className="svg"
                        src={item <= reviewSummary ? "/images/icon-star.svg" : "/images/icon-star-gray.svg"}
                        alt=""
                        onClick={onClickFunc ? (() => onClickFunc(item)) : void 0} />
                </li>);
            }
            )}
        </ul>
    );
};
