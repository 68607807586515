import { getLinkArticle } from "./articleComponent";
import { getThumbnail } from "./articleComponent";
import { isArray, isObject } from "lodash";
import dayjs from "dayjs";
import { changeMonthFirst } from "utilities/helper";

const LABEL = {
    0: 'Nháp',
    1: '',
    2: 'Chờ duyệt',
    100: 'Mới'
};

export default function Detail360({ item, index, perRow, thumbnail, link, isSlide = false }) {
    link = link || getLinkArticle(item, 'kho-360');
    thumbnail = thumbnail || getThumbnail(item);
    const style = {
        cover: {
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 55.37%, rgba(0, 0, 0, 0.85) 97.66%), url("${thumbnail}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: 24,
            padding: 16,
            color: '#fff',
            height: !isSlide ? '269px' : '190px',
            display: 'flex'
        }
    };

    const information = {};
    if (isArray(item.information)) {
        item.information.forEach(info => {
            const infoHouse = Object.entries(info)[0];
            if (infoHouse[0] == 'constructor') infoHouse[0] = 'constructorApi';
            information[infoHouse[0]] = infoHouse[1];
        });
    }

    if (isObject(item.information)) {
        Object.values(item.information).forEach(info => {
            const infoHouse = Object.entries(info)[0];
            if (infoHouse[0] == 'constructor') infoHouse[0] = 'constructorApi';
            information[infoHouse[0]] = infoHouse[1];
        });
    }

    const location = information["house_name"];
    const area = information["area_total"] || information["area_real"];
    const designer = information["designer"];

    if (item.status_code == 1 && dayjs().diff(dayjs(item.published_at_detail_formatted, "DD/MM/YYYY HH:mm"), 'day') < 2) {
        item.status_code = 100;
    };

    return <a key={index} href={link}>
        <div style={style.cover} className="position-relative">
            <div className="position-absolute" style={{ top: 16, right: 16 }}>
                <img src="/images/icon360.png" width={40}></img>
            </div>
            {
                LABEL[item.status_code] && <div className="position-absolute moi-label"
                    style={{ top: 16, left: 16 }}>
                    {LABEL[item.status_code]}
                </div>
            }

            <div className="" style={{ bottom: 16, display: 'inline-block', alignSelf: 'flex-end' }}>
                <div>
                    <p className="display-2-line" style={{ fontWeight: '600', color: '#FFF', lineHeight: '20px' }}>{item.title}</p>
                </div>
                {!isSlide && <div style={{ display: 'flex' }} className="mt-2">
                    <div style={{ width: '48px', display: "flex", alignItems: "center", justifyContent: "center", marginRight: 10 }}>
                        <img src={item.user?.avatar} width={48} style={{ borderRadius: '50%' }}></img>
                    </div>

                    <div style={{ width: 'calc(100% - 58px)', fontSize: 12, lineHeight: '14px' }}>
                        {location && <p className="display-1-line">
                            <img className="mr-1" src="/images/Location.svg" />{location}
                        </p>}
                        {area && <p className="display-1-line mt-1" >
                            <img className="mr-1" src="/images/Bed.svg" />{area} m2
                        </p>}
                        {designer && <p className="display-1-line mt-1" >
                            <img className="mr-1" src="/images/Design.svg" />{designer}
                        </p>}
                    </div>
                </div>}
            </div>
        </div>
    </a>;
};

export function Kho360({ item, index, perRow, thumbnail, link }) {
    perRow = perRow || 4;

    return <li key={index} className={`li-${perRow} li-1-mb`}>
        <Detail360 item={item} index={index} perRow={perRow} thumbnail={thumbnail} link={link} />
    </li>;
}